
/**
 * @roxi/routify 2.18.4
 * File generated Thu Jun 13 2024 17:24:36 GMT+0200 (Central European Summer Time)
 */

export const __version = "2.18.4"
export const __timestamp = "2024-06-13T15:24:36.555Z"

//buildRoutes
import { buildClientTree } from "@roxi/routify/runtime/buildRoutes"

//imports
import __fallback from '../src/pages/_fallback.svelte'
import _area_privada_aviso_legal from '../src/pages/area_privada/aviso_legal.svelte'
import _area_privada_contacto from '../src/pages/area_privada/contacto.svelte'
import _area_privada_index from '../src/pages/area_privada/index.svelte'
import _area_privada_informacion_tecnica__technicalId from '../src/pages/area_privada/informacion_tecnica/[technicalId].svelte'
import _area_privada_informacion_tecnica_index from '../src/pages/area_privada/informacion_tecnica/index.svelte'
import _area_privada_mis_datos from '../src/pages/area_privada/mis_datos.svelte'
import _area_privada_mis_proyectos__projectId from '../src/pages/area_privada/mis_proyectos/[projectId].svelte'
import _area_privada_mis_proyectos_index from '../src/pages/area_privada/mis_proyectos/index.svelte'
import _area_privada_novedades__newsId from '../src/pages/area_privada/novedades/[newsId].svelte'
import _area_privada_novedades_index from '../src/pages/area_privada/novedades/index.svelte'
import _area_privada_politica_cookies from '../src/pages/area_privada/politica_cookies.svelte'
import _area_privada_politica_privacidad from '../src/pages/area_privada/politica_privacidad.svelte'
import _area_privada__layout from '../src/pages/area_privada/_layout.svelte'
import _cambiar_clave from '../src/pages/cambiar_clave.svelte'
import _index from '../src/pages/index.svelte'
import _recuperar_clave from '../src/pages/recuperar_clave.svelte'
import __layout from '../src/pages/_layout.svelte'

//options
export const options = {}

//tree
export const _tree = {
  "root": true,
  "children": [
    {
      "isFallback": true,
      "path": "/_fallback",
      "component": () => __fallback
    },
    {
      "isDir": true,
      "children": [
        {
          "isPage": true,
          "path": "/area_privada/aviso_legal",
          "id": "_area_privada_aviso_legal",
          "component": () => _area_privada_aviso_legal
        },
        {
          "isPage": true,
          "path": "/area_privada/contacto",
          "id": "_area_privada_contacto",
          "component": () => _area_privada_contacto
        },
        {
          "isIndex": true,
          "isPage": true,
          "path": "/area_privada/index",
          "id": "_area_privada_index",
          "component": () => _area_privada_index
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isPage": true,
              "path": "/area_privada/informacion_tecnica/:technicalId",
              "id": "_area_privada_informacion_tecnica__technicalId",
              "component": () => _area_privada_informacion_tecnica__technicalId
            },
            {
              "isIndex": true,
              "isPage": true,
              "path": "/area_privada/informacion_tecnica/index",
              "id": "_area_privada_informacion_tecnica_index",
              "component": () => _area_privada_informacion_tecnica_index
            }
          ],
          "path": "/area_privada/informacion_tecnica"
        },
        {
          "isPage": true,
          "path": "/area_privada/mis_datos",
          "id": "_area_privada_mis_datos",
          "component": () => _area_privada_mis_datos
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isPage": true,
              "path": "/area_privada/mis_proyectos/:projectId",
              "id": "_area_privada_mis_proyectos__projectId",
              "component": () => _area_privada_mis_proyectos__projectId
            },
            {
              "isIndex": true,
              "isPage": true,
              "path": "/area_privada/mis_proyectos/index",
              "id": "_area_privada_mis_proyectos_index",
              "component": () => _area_privada_mis_proyectos_index
            }
          ],
          "path": "/area_privada/mis_proyectos"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isPage": true,
              "path": "/area_privada/novedades/:newsId",
              "id": "_area_privada_novedades__newsId",
              "component": () => _area_privada_novedades__newsId
            },
            {
              "isIndex": true,
              "isPage": true,
              "path": "/area_privada/novedades/index",
              "id": "_area_privada_novedades_index",
              "component": () => _area_privada_novedades_index
            }
          ],
          "path": "/area_privada/novedades"
        },
        {
          "isPage": true,
          "path": "/area_privada/politica_cookies",
          "id": "_area_privada_politica_cookies",
          "component": () => _area_privada_politica_cookies
        },
        {
          "isPage": true,
          "path": "/area_privada/politica_privacidad",
          "id": "_area_privada_politica_privacidad",
          "component": () => _area_privada_politica_privacidad
        }
      ],
      "isLayout": true,
      "path": "/area_privada",
      "id": "_area_privada__layout",
      "component": () => _area_privada__layout
    },
    {
      "isPage": true,
      "path": "/cambiar_clave",
      "id": "_cambiar_clave",
      "component": () => _cambiar_clave
    },
    {
      "isIndex": true,
      "isPage": true,
      "path": "/index",
      "id": "_index",
      "component": () => _index
    },
    {
      "isPage": true,
      "path": "/recuperar_clave",
      "id": "_recuperar_clave",
      "component": () => _recuperar_clave
    }
  ],
  "isLayout": true,
  "path": "/",
  "id": "__layout",
  "component": () => __layout
}


export const {tree, routes} = buildClientTree(_tree)

