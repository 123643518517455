<style global lang="postcss">
    @import './Calendar.css';
</style>

<script type="ts">
    import type { PartialItem } from '@directus/sdk';
    import type { Project } from '../../../types/Project';
    import type { News } from '../../../types/News';
    import { directusSDK } from '../../../api';
    import { onMount } from 'svelte';
    import type { Configuration } from '../../../types/Configuration';
    import Calendar from '@event-calendar/core';
    import DayGrid from '@event-calendar/day-grid';
    import type { CalendarEvent } from '../../../types/CalendarEvent';
    import NewsModal from '../../News/NewsModal.svelte';

    export let project: PartialItem<Project> = null;
    let isOpen = false;
    let newsId = null;

    function getFirstDay(d: Date){
        return new Date(d.getFullYear(), d.getMonth(), 1);
    }

    function getLastDay(d: Date){
        return new Date(d.getFullYear(), d.getMonth() + 1, 0);
    }

    const currentDate = new Date();
    let firstDayOfMonth = getFirstDay(currentDate)
    let lastDayOfMonth = getLastDay(currentDate)

    function dateChange(info) {
        const currentStart = info.view.currentStart
        firstDayOfMonth = new Date(currentStart.getFullYear(), currentStart.getMonth(), 1);
        lastDayOfMonth = new Date(currentStart.getFullYear(), currentStart.getMonth() + 1, 0);
        const sameMonth = currentStart.getMonth() === currentDate.getMonth() && currentStart.getFullYear() === currentDate.getFullYear()

        options.headerToolbar = {
            start: 'title',
            center: '',
            end:
                sameMonth
                    ? 'prev next'
                    : 'today prev next',
        };

        getNews();
    }

    const openModal = (eventId: number) => {
        isOpen = true;
        newsId = eventId;
    };

    let newsList: Array<PartialItem<News>> = [];
    const plugins = [DayGrid];
    let options: Record<string, any> = {
        view: 'dayGridMonth',
        locale: 'es-ES',
        firstDay: 1, // set monday as first column
        buttonText: {
            today: 'Hoy',
        },
        events: [],
        eventClick: (info) => openModal(info.event.id),
        datesSet: dateChange,
    };

    async function getNews() {
        const calendarCategoryResponse = await directusSDK.getItems('configuracion', {
            fields: ['categoria_calendario'],
        });

        const calendarCategory: PartialItem<Configuration> = calendarCategoryResponse.data as PartialItem<Configuration>;
        const calendarCategoryId: number = calendarCategory.categoria_calendario;

        const newsFilter = {
            filter: {
                proyecto: project.id,
                categoria: calendarCategoryId,
                fecha: {
                    _between: [firstDayOfMonth.toISOString(),lastDayOfMonth.toISOString()]
                },
            },
        };

        newsList = (await directusSDK.getItems('novedades', newsFilter)).data;

        const events: CalendarEvent[] = newsList.map((ev) => {
            const d = new Date(ev.fecha);
            return {
                id: ev.id.toString(),
                title: ev.titulo,
                start: d,
                end: d,
                allDay: true,
                display: 'auto',
            };
        });

        options.events = events;
    }

    onMount(() => {
        getNews();
    });
</script>

<div class="col-span-2 px-8 py-4 bg-white rounded-lg shadow-md ">
    <h1 class="text-left text-3xl font-semibold text-gray-800 lg:text-4xl ">Calendario</h1>
    <Calendar plugins="{plugins}" options="{options}" />
    <NewsModal bind:isOpen bind:newsId />
</div>
